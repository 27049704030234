import React from 'react';
import { graphql } from 'gatsby';

import { ContactPage } from 'components/Contact';

export default function PageContact(props) {
  return <ContactPage {...props} />;
}

export const query = graphql`
query ContactUsPageQuery {
  fields: strapiContactUs {
    seo_title
    seo_description
    heading
    subheading
  }

  sections: allStrapiContactSection {
    nodes {
      heading
      order
      contact_addresses {
        id
        heading
        address {
          data {
            address
          }
        }
        map_link
        map_image {
          localFile {
            url
          }
        }
      }
    }
  }
}
`;
