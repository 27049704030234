import React, { useMemo } from 'react';
import cn from 'classnames';
import pick from 'lodash-es/pick';

import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';

import { useStrapiSocialLinks } from 'hooks';

import { sortByKey } from 'utils';

import './ContactPage.styles.scss';

function parseAddress(address) {
  return address.replace(/(\r\n|\n\r|\r|\n)/g, '<br/>');
}

export const ContactPage = ({ className, ...props }) => {
  const { fields, sections } = props.data;
  const _className = cn('contact', className);
  const socialLinks = useStrapiSocialLinks();

  const _socialLinks = useMemo(() => {
    return Object.entries(
      pick(socialLinks, ['contactEmail', 'linkedin', 'twitter', 'instagram'])
    );
  }, [socialLinks]);

  const renderContacts = (
    <address className='contact-links'>
      {_socialLinks.map(([key, link]) => {
        const _className = cn('contact-links-item', key);

        return (
          <a
            key={key}
            className={_className}
            href={link.url}
            title={link.label}
            target='_blank'
            rel='noreferrer'
          >
            {link.label}
          </a>
        );
      })}
    </address>
  );

  const renderAddresses = sectionAddresses => {
    return sectionAddresses.map(address => {
      return (
        <div key={address.id} className='contact-address'>
          {address.map_image && (
            <div className='contact-address-map'>
              <a
                className='contact-address-map-link'
                href={address.map_link}
                title={address.heading}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='contact-address-map-image'
                  src={address.map_image.localFile.url}
                  alt={address.heading}
                />
              </a>
            </div>
          )}
          <address
            className='contact-address-details'
            dangerouslySetInnerHTML={{
              __html: parseAddress(
                `<span class='city'>${address.heading}</span>\n${address.address.data.address}`
              )
            }}
          />
        </div>
      );
    });
  };

  const renderSections = (
    <div className='contact-sections'>
      {sortByKey(sections.nodes).map(section => (
        <Section
          key={section.heading}
          className='contact-section'
          heading={section.heading}
        >
          {renderAddresses(section.contact_addresses)}
        </Section>
      ))}
    </div>
  );

  return (
    <Page
      className={_className}
      meta={{ title: fields.seo_title, description: fields.seo_description }}
      withContainer
    >
      <Section
        heading={fields.heading}
        subheading={fields.subheading}
        headerChildren={renderContacts}
        variant='aside'
      >
        {renderSections}
      </Section>
    </Page>
  );
};
